#menu {
    background-color: black;
}

#menu .container {
    display: grid;
    grid-template-columns: 13% 1fr;
    align-items: center;
    padding: .5rem 0;
}

#menu .container .left {
    text-align: left;
}

#menu .container .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

#menu .container .right #ctaWpp {
    background-color: transparent;
    border: 1px solid #1EB648;
    width: 22%;
}

#menu .container .right #ctaWpp a figure {
    width: 15%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        grid-template-columns: 1fr;
    }

    #menu .container .left {
        text-align: center;
    }

    #menu .container .left img {
        width: 30%;
    }

    #menu .container .right {
        display: none;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}