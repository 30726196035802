#line {
  height: 3px;
  width: 450px;
  background-color: #FECE15;
  margin: -1.5rem auto -.5rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #line {
    width: 250px;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #line {
    width: 150px;
  }
}