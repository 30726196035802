#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

#header .header1 .container .title {
    color: #FECE15;
    text-align: left;
    max-width: 700px;
    line-height: 60px;
}

#header .header1 .container .title span {
    color: white;
}

#header .header1 .container .text {
    color: white;
    max-width: 700px;
}

#header .header1 .container #ctaWpp a figure img {
    width: 100%;
}

#header .header2 {
    background: url("../../assets/header2bg.png") no-repeat center center;
    background-size: cover;
}

#header .header2 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding-bottom: 250px;
}

#header .header2 .container .right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .right .title {
    text-align: left;
    color: #FECE15;
}

#header .header2 .container .right .mobile {
    display: none;
}

#header .header2 .container .right .text {
    color: white;
}

#header .header2 .container .right #ctaWpp {
    width: 60%;
}

#header .header3 {
    position: absolute;
    margin-top: -160px;
    left: 7.5vw;
}

#header .header3 .container {
    background-color: white;
    padding: 2rem 5rem;
    border-radius: 2rem;
}

#header .header3 .container .text {
    text-align: center;
    font-weight: bold;
}

#header .header3 .container #ctaWpp {
    margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 80vh;
    }

    #header .header1 .container {
        height: 100%;
        justify-content: flex-end;
        display: flex;
        padding-bottom: 4rem;
    }

    #header .header1 .container .title {
        text-align: center;
        max-width: 100%;
        line-height: 55px;
    }

    #header .header1 .container .text {
        max-width: 100%;
        text-align: center;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
    }

    #header .header2 {
        background: url("../../assets/mobile/header2bg.png") no-repeat center center;
        background-size: cover;
    }

    #header .header2 .container {
        grid-template-columns: 1fr;
        padding-bottom: 3rem;
    }

    #header .header2 .container .left {
        display: none;
    }

    #header .header2 .container .right .title {
        text-align: center;
    }

    #header .header2 .container .right .mobile {
        display: block;
    }

    #header .header2 .container .right .text {
        color: white;
        text-align: center;
    }

    #header .header2 .container .right #ctaWpp {
        width: 60%;
        margin: 0 auto;
    }

    #header .header3 {
        position: relative;
        margin-top: 0;
        left: 0;
    }

    #header .header3 .container {
        padding: 2rem 0;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container {
        padding-bottom: 2.5rem;
    }

    #header .header1 .container .title {
        line-height: 40px;
    }

    #header .header2 .container .right #ctaWpp {
        width: 75%;
    }
}