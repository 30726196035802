#footer .footer1 {
    background-color: black;
}

#footer .footer1 .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

#footer .footer1 .container .logo {
    width: 80%;
}

#footer .footer1 .container .middle {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#footer .footer1 .container .middle .item_title {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

#footer .footer1 .container .middle .text {
    font-size: 16px;
    color: white;
}

#footer .footer1 .container .map {
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

#footer .footer1 .container .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#footer .footer1 .container .right .item_title {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

#footer .footer1 .container .right .ctas {
    display: flex;
    flex-direction: column;
}

#footer .footer1 .container .right .ctas .cta {
    display: grid;
    grid-template-columns: 13% 1fr;
    align-items: center;
    color: white;
    margin: -.5rem 0;
}

#footer .footer1 .container .right .ctas .cta figure {
    margin-top: .4rem;
}

#footer .footer1 .container .right .ctas .cta p {
    font-size: 16px;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container p {
    color: black;
    font-size: 20px;
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        grid-template-columns: 1fr;
    }

    #footer .footer1 .container .logo {
        width: 100%;
    }

    #footer .footer1 .container .logo img {
        width: 30%;
    }

    #footer .footer1 .container .middle .text {
        font-size: 16px;
    }

    #footer .footer1 .container .map {
        height: 300px;
    }

    #footer .footer1 .container .right .ctas {
        display: flex;
        flex-direction: column;
    }

    #footer .footer1 .container .right .ctas .cta {
        grid-template-columns: 6% 1fr;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
    }

    #footer .footer2 .container p {
        font-size: 18px;
        text-align: center;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container .logo img {
        width: 35%;
    }

    #footer .footer1 .container .middle .text {
        font-size: 14px;
    }

    #footer .footer1 .container .map {
        height: 200px;
    }

    #footer .footer2 .container .text {
        font-size: 14px;
    }
}