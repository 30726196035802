#about .about1 {
  background-color: #EEF1F6;
}

#about .about1 .container {
  padding-top: 600px;
  padding-bottom: 250px;
}

#about .about1 .container p {
  text-align: center;
}

#about .about1 .container .text1 {
  font-size: 40px;
}

#about .about1 .container .text2 {
  color: #535968;
}

#about .about1 .container .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

#about .about1 .container .gallery .item {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  background-color: white;
  border-radius: 7px;
  border-bottom: 10px solid #FECE15;
  padding: 1rem;
}

#about .about1 .container .gallery .item .top img {
  width: 25%;
}

#about .about1 .container .gallery .item .item_title {
  text-align: center;
  font-weight: bold;
}

#about .about1 .container .gallery .item .item_text {
  text-align: center;
  color: #313439;
  font-size: 16px;
}

#about .about1 .container .text {
  text-align: center;
  font-weight: bold;
}

#about .about1 .container #ctaWpp {
  margin: 0 auto;
}

#about .about2 {
  position: absolute;
  margin-top: -220px;
}

#about .about2 .container {
  margin-left: 7.5vw;
}

#about .about2 .container figure img {
  border-radius: 10px;
}

#about .about3 {
  padding-top: 300px;
}

#about .about3 .container .gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 1rem;
}

#about .about3 .container .gallery .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#about .about3 .container .gallery .right .text1 {
  font-size: 30px;
}

#about .about3 .container .gallery .right #ctaWpp {
  width: 60%;
}

#about .about4 {
  background-color: #EEF1F6;
}

#about .about4 .container p {
  text-align: center;
}

#about .about4 .container .text1 {
  font-size: 40px;
}

#about .about4 .container .text2 {
  color: #535968;
}

#about .about4 .container .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

#about .about4 .container .gallery .item {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  background-color: white;
  border-radius: 7px;
  border-bottom: 10px solid #FECE15;
  padding: 1rem;
}

#about .about4 .container .gallery .item .top img {
  width: 25%;
}

#about .about4 .container .gallery .item .item_title {
  text-align: center;
  font-weight: bold;
}

#about .about4 .container .gallery .item .item_text {
  text-align: center;
  color: #313439;
  font-size: 16px;
}

#about .about4 .container .text {
  text-align: center;
  font-weight: bold;
}

#about .about4 .container #ctaWpp {
  margin: 0 auto;
}

#about .about5 {
  background: url("../../assets/about5bg.png") no-repeat center center;
  background-size: cover;
}

#about .about5 .container .title {
  color: #FECE15;
}

#about .about5 .container p {
  text-align: center;
  color: white;
}

#about .about5 .container .text1 {
  font-weight: bold;
  font-size: 30px;
}

#about .about5 .container #ctaWpp {
  margin: 0 auto;
}

#about .about6 .container p {
  text-align: center;
}

#about .about6 .container .text1 {
  font-size: 40px;
}

#about .about6 .container .gallery {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#about .about7 {
  background-color: #FECE15;
  display: grid;
  grid-template-columns: 45% 1fr;
  align-items: center;
  gap: 4rem;
}

#about .about7 .left {
  width: 100%;
}

#about .about7 .left img {
  width: 100%;
  display: block;
}

#about .about7 .right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 7.5vw;
}

#about .about7 .right .text {
  width: 500px;
}

#about .about7 .right .cta {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#about .about7 .right .cta #ctaWpp {
  white-space: nowrap;
  width: 40%;
}

#about .about7 .right .cta #ctaWpp figure {
  width: 15%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container {
    padding: 3rem 0;
  }

  #about .about1 .container .text1 {
    font-size: 28px;
  }

  #about .about1 .container .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  #about .about1 .container .gallery .item .top img {
    width: 30%;
  }

  #about .about1 .container .gallery .item .item_title {
    text-align: center;
    font-weight: bold;
  }

  #about .about1 .container .gallery .item .item_text {
    font-size: 14px;
  }

  #about .about2 {
    position: relative;
    margin-top: 0;
  }

  #about .about2 .container {
    margin-left: 5vw;
  }

  #about .about3 {
    padding-top: 0;
  }

  #about .about3 .container .gallery {
    grid-template-columns: 1fr;
  }

  #about .about3 .container .gallery .right {
    gap: 2rem;
  }

  #about .about3 .container .gallery .right .text1 {
    text-align: center;
    font-size: 23px;
  }

  #about .about3 .container .gallery .right .text {
    text-align: center;
  }

  #about .about3 .container .gallery .right #ctaWpp {
    width: 60%;
    margin: 0 auto;
  }

  #about .about4 .container .text1 {
    font-size: 28px;
  }

  #about .about4 .container .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  #about .about4 .container .gallery .item .top img {
    width: 30%;
  }

  #about .about4 .container .gallery .item .item_text {
    font-size: 14px;
  }

  #about .about5 .container .text1 {
    font-size: 23px;
  }

  #about .about6 .container .text1 {
    font-size: 32px;
  }

  #about .about6 .container .gallery {
    flex-direction: column;
  }

  #about .about7 {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding-bottom: 3rem;
  }

  #about .about7 .right {
    padding: 0 5vw;
  }

  #about .about7 .right .title {
    text-align: center;
  }

  #about .about7 .right .text {
    text-align: center;
    width: 100%;
  }

  #about .about7 .right .cta {
    justify-content: center;
  }

  #about .about7 .right .cta #ctaWpp {
    width: 40%;
  }

  #about .about7 .right .cta #ctaWpp figure {
    width: 14%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .text1 {
    font-size: 16px;
  }

  #about .about1 .container .gallery .item .item_text {
    font-size: 12px;
  }

  #about .about3 .container .gallery .right .text1 {
    font-size: 16px;
  }

  #about .about3 .container .gallery .right #ctaWpp {
    width: 75%;
  }

  #about .about4 .container .text1 {
    font-size: 16px;
  }

  #about .about4 .container .gallery .item .item_text {
    font-size: 12px;
  }

  #about .about5 .container .text1 {
    font-size: 16px;
  }

  #about .about6 .container .text1 {
    font-size: 24px;
  }

  #about .about7 .right .cta {
    flex-direction: column;
  }

  #about .about7 .right .cta #ctaWpp {
    width: 75%;
  }
}